import IdleTimer from '@components/session-time-out/idle-timer';
import useFetchCorlPortal from '@hooks/use-fetch-corl-portal';
import { IUserSessionConfigResponse } from '@type/api';

const SessionTimeOut = () => {
  const {data, loading, error} = useFetchCorlPortal<IUserSessionConfigResponse>('configurations?codes=interactiveTimeout,waitTimeout');

  if (loading || error || !data?.interactiveTimeout) {
    return <></>
  }

  const timeout = +data.interactiveTimeout * 60 * 1000; // ms
  const promptBeforeIdle = +data.waitTimeout * 60 * 1000; // ms

  return (
    <IdleTimer
      timeout={timeout}
      promptBeforeIdle={promptBeforeIdle}
    />
  );
};

export default SessionTimeOut;
