/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
"use client";

import useFetchCorlPortal from "@hooks/use-fetch-corl-portal";
import { IPendoApiKeyConfigResponse } from "@type/api";
import { useAppSelector } from "@/app/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import Script from "next/script";
import { useMemo, useEffect, useState } from "react";

interface IPendoInitProps {
	isAuthorized: boolean;
	doneCheckingAuthorizationOnPageReload: boolean;
}

export default function PendoInit({ isAuthorized, doneCheckingAuthorizationOnPageReload }: IPendoInitProps) {
	const { data, loading: loadingPendoApiKey } = useFetchCorlPortal<IPendoApiKeyConfigResponse>('configurations?codes=pendoApiKey');
	const { firstName, lastName } = useAppSelector((state) => state.currentUser);
	const { user } = useAuth0();
	const { name: clientName, id: clientId } = useAppSelector((state) => state.currentClient);
	const [isPendoInitialized, setIsPendoInitialized] = useState(false);
	const [isPendoAgentSnippetLoaded, setIsPendoAgentSnippetLoaded] = useState(false);

	useEffect(() => {
		if (!isPendoAgentSnippetLoaded) {
			return;
		}

		if (!doneCheckingAuthorizationOnPageReload) {
			return;
		}

		if (!isAuthorized && isPendoInitialized) {
			(window as any).pendo?.teardown?.();
			setIsPendoInitialized(false);
			return;
		}

		if (user?.email && firstName && lastName && clientName && clientId) {
			const metadata = {
				visitor: {
					id: user?.email,
					firstName,
					lastName,
				},
				account: {
					id: clientName,
				}
			};

			if (!isPendoInitialized) {
				(window as any).pendo?.initialize?.(metadata);
				setIsPendoInitialized(true);
			} else {
				(window as any).pendo?.updateOptions?.(metadata);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthorized, user?.email, firstName, lastName, clientName, clientId, isPendoAgentSnippetLoaded, doneCheckingAuthorizationOnPageReload]);

	return useMemo(() => {
		if (!doneCheckingAuthorizationOnPageReload || (doneCheckingAuthorizationOnPageReload && !isAuthorized) || loadingPendoApiKey || !data?.pendoApiKey) {
			return <></>;
		}

		return (
			<Script id="pendo-agent-snippet" data-testid="pendo-agent-snippet" onReady={() => setIsPendoAgentSnippetLoaded(true)}>
				{`
					(function (apiKey) {
						(function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
						v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
							o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
							y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
							z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
					})('${data?.pendoApiKey}') 
				`}
			</Script>
		)
	}, [doneCheckingAuthorizationOnPageReload, isAuthorized, loadingPendoApiKey, data?.pendoApiKey]);
}
