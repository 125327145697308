"use client"

import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import useUserInfo from '@hooks/use-user-info';
import { useEffect } from 'react';

const envEnableRum = ['dev', 'qa', 'uat', 'prod'];

if (process.env.NEXT_PUBLIC_ENV && process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN && 
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID && envEnableRum.includes(process.env.NEXT_PUBLIC_ENV)) {

  datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'meditportal_client',
      env: process.env.NEXT_PUBLIC_ENV,
      version: process.env.NEXT_PUBLIC_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls:  process.env.NEXT_PUBLIC_PORTAL_API_HOST ? [{match: process.env.NEXT_PUBLIC_PORTAL_API_HOST, propagatorTypes: ['datadog']}] : []
  })
};

export default function DatadogInit() {
  const { firstName, lastName } = useUserInfo();
  const {  user } = useAuth0();

  useEffect(() => {
    datadogRum.setUser({
      name: `${firstName} ${lastName}`,
      email: user?.email
    });
  }, [firstName, lastName, user?.email]);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
