import Image from 'next/image';
import Link from 'next/link';

import useDirtyGlobal from '@hooks/use-dirty-global';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const rangeYearText = currentYear <= 2024 ? '2024' : `2024-${currentYear}`;
  const { router, dirtyModal } = useDirtyGlobal();
  return (
    <>
      {dirtyModal}
      <footer>
        <div className="shadow-footer w-full h-[72px] bg-white flex justify-between items-center">
          <div className="w-full max-w-[1280px] px-6 sm:px-[55px] conatiner mx-auto flex justify-between items-center">
            <Link
              href="/"
              onClick={(e) => {
                e.preventDefault();
                router.push('/');
              }}
            >
              <Image src="/footerIcon.svg" alt="logo" width={106.5} height={24} />
            </Link>
            <div className="font-normal text-[14px] leading-4 text-blue-secondary">
              Copyright © Meditology Services {rangeYearText}
            </div>
          </div>
        </div>
        <div className="w-full h-[70px] bg-blue-primary"></div>
      </footer>
    </>
  );
};

export default Footer;
