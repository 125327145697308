export const mapResponseToDTO = <T, U>(responseDTO: U, propertyMappings?: Record<string, keyof T>): T => {
  const mappedDTO: Partial<T> = {};

  for (const key in responseDTO) {
    if (propertyMappings && key in propertyMappings) {
      mappedDTO[propertyMappings[key]] = responseDTO[key] as unknown as T[keyof T];
    } else {
      mappedDTO[key as unknown as keyof T] = responseDTO[key] as unknown as T[keyof T];
    }
  }

  return mappedDTO as T;
};

export const mapArray = <T, U>(arr: U[], mappingFunction: (apiData: U) => T): T[] => {
  const mappedArr: T[] = [];
  arr.forEach((item) => {
    mappedArr.push(mappingFunction(item));
  });
  return mappedArr;
};


export const parseQueryURL = (
  path: string,
  paramsObject?: {
    [x: string]:
      | string
      | number
      | boolean
  }
) => {
  if (!paramsObject) return path;

  const params = Object.keys(paramsObject)
    .reduce<Array<string>>((prev, current) => {
      if (
        typeof paramsObject[current] === 'undefined' ||
        (typeof paramsObject[current] === 'string' && (paramsObject[current] as unknown as never[]).length === 0)
      )
        return prev;
      return [...prev, [current, encodeURIComponent(paramsObject[current])].join('=')];
    }, [])
    .join('&');

  return [path, params.trim()].join('?');
};

// Check text is JSON string or not
export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
