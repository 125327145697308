'use client'
import { TOAST_TYPE } from '@/app/constants/texts';
import { useAppDispatch, useAppSelector } from '@/app/redux/store';
import { closeToast } from '@/app/redux/slices/toast-slice';

import { useEffect, useMemo } from 'react';

import classNames from 'classnames';

import Button from '../button';
import { Icon } from '../icon';
import { ICONS_SOURCE } from '@components/icon/constant';
import { sanitizeHtml } from '@helpers/common-helpers';

export type ToastType = (typeof TOAST_TYPE)[keyof typeof TOAST_TYPE];

const Toast = () => {
  const { message, type = TOAST_TYPE.SUCCESS, isOpen } = useAppSelector((state) => state.toast);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (type === TOAST_TYPE.SUCCESS && isOpen) {
      const timeoutId = setTimeout(() => {
        dispatch(closeToast());
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [type, isOpen, dispatch, message]);

  const prepresentIcon: {
    variant?: keyof typeof ICONS_SOURCE
    svgClassName?: {
      fill: string,
      stroke?: string,
    },
  } = useMemo(() => {
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        return {
          variant: 'circle-success-check',
          svgClassName: {
            fill: `fill-green-primary`,
            stroke: 'stroke-green-secondary fill-green-secondary',
          },
        };
      case TOAST_TYPE.WARNING:
        return {
          variant: 'warning-hand',
          svgClassName: {
            fill: `fill-yellow-primary`,
          },
        };
      case TOAST_TYPE.ERROR:
        return {
          variant: undefined,
        };
      case TOAST_TYPE.DISCONNECT:
        return { variant: TOAST_TYPE.DISCONNECT, svgClassName: undefined };
    }
  }, [type]);

  const colorClasses = useMemo(() => {
    switch (type) {
      case 'success':
        return {
          text: 'text-green-primary',
          bg: 'bg-green-secondary',
          fill: 'fill-green-primary',
        };
      case TOAST_TYPE.WARNING:
        return {
          text: 'text-yellow-primary',
          bg: 'bg-yellow-secondary',
          fill: 'fill-yellow-primary',
        };
      case TOAST_TYPE.ERROR:
        return {
          text: 'text-red-primary',
          bg: 'bg-red-secondary',
          fill: 'fill-red-primary',
        };
      case TOAST_TYPE.DISCONNECT:
        return {
          text: 'text-white',
          bg: 'bg-blue-primary',
          fill: 'fill-white',
        };
    }
  }, [type]);

  const containerClass = classNames(
    'animate-show-down flex absolute top-[5px] gap-2 px-4 min-h-[50px] items-center rounded shadow-toast w-full sm:w-3/5 sm:translate-x-[-50%] sm:left-[50%] max-w-[733px]',
    colorClasses.bg
  );

  const messageClass = classNames('toast-global flex-1 text-sm p-3 leading-normal font-light break-words', colorClasses.text);

  return (
    <>
      {isOpen ? (
        <div className="sticky top-0 z-[100] ">
          <div className={containerClass}>
            {prepresentIcon.variant && <Icon variant={prepresentIcon.variant} {...prepresentIcon} />}
            <div className={messageClass} dangerouslySetInnerHTML={{ __html: sanitizeHtml(message ?? 'Empty message') }} />
            {type !== 'success' && (
              <Button
                className="bg-transparent flex items-center p-1 border-none"
                size="sm"
                onClick={() => dispatch(closeToast())}
              >
                <Icon variant="close" svgClassName={{ fill: colorClasses.fill }} width={16} height={16} />
              </Button>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Toast;
