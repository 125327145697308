import { InputHTMLAttributes, ReactElement } from 'react';

import classNames from 'classnames';

type CheckboxPropType = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label?: string | ReactElement;
};

const Checkbox = ({ id = 'checkbox-id', value, checked, className, label }: CheckboxPropType) => {
  return (
    <div className={classNames('flex gap-2 items-center', className)}>
      <input
        id={id}
        type="checkbox"
        value={value}
        checked={checked}
        className={`w-[18px] h-[18px] bg-gray-100 border-gray-300 rounded }`}
      />
      {label && (
        <label htmlFor={id} className="cursor-pointer">
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
