import {Icon} from '@components/icon';
import classNames from 'classnames';
import {useAppSelector} from '@/app/redux/store';

const StatusCard = () => {
  const { background, messageClassName, variantIcon, isOpen, svgClassName, message } = useAppSelector((state) => state.statusCard);

  if (!isOpen) {
    return <></>;
  }

  return (
    <div className={classNames('w-full absolute shadow-toast', background)}>
      <div className="flex items-center gap-2 max-w-[1280px] px-4 h-[50px] mx-auto">
        <div>
          <Icon variant={variantIcon} svgClassName={svgClassName} />
        </div>
        <div className={messageClassName}>{message}</div>
      </div>
    </div>
  );
}

export default StatusCard;
