'use client';

import { useAppSelector } from '@/app/redux/store';
import BackButton from '@components/back-button';
import Breadcrumb from '../breadcrumb';
import PreviousButton from '@components/previous-button';
import NextButton from '@components/next-button';

interface IPageHeaderProps {
  isShow?: boolean;
}

const PageHeader = (props: IPageHeaderProps) => {
  const header = useAppSelector(state => state.header);

  return (
    <div className={`bg-white h-[120px] flex items-center shadow-header rounded-b-md`}>
      <div className="max-w-[1280px] container px-6 sm:px-[42px]">
        <div className={header.rightHeaderComponent ? "flex justify-between w-full gap-1" : ''}>
          <div className={`${props.isShow ? '' : 'invisible'} ${header.rightHeaderComponent ? 'basis-1/4' : ''}`}>
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                {header?.isShowBackButton && <BackButton name={header.backButtonTitle} />}
                {header?.isShowBreadcrumb && <Breadcrumb getTextGenerator={header.getTextGenerator} />}
              </div>
              <div className="flex items-center gap-8">
                {header?.isShowPreNextButton && (<PreviousButton name={header.preButtonTitle} onClick={header.onClickPreCallBack} disabled={header.disabledPreButton} />)}
                {header?.isShowPreNextButton && (<NextButton name={header.nextButtonTitle} onClick={header.onClickNextCallBack} disabled={header.disabledNextButton} />)}
              </div>
            </div>
            <div className='flex items-end justify-between w-full'>
              <h1 className="font-light tracking-[-0.03em] text-left text-4xl leading-10 overflow-hidden break-words line-clamp-2" title={header.title}>{header.title}</h1>
              {header.rightButtonEl}
            </div>
          </div>
          {header.rightHeaderComponent}
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
