/* eslint-disable @typescript-eslint/no-floating-promises */
'use client'
import { PermissionContext } from '@/app/contexts/permission-context';
import { messageResources } from '@/app/data/i18n';
import {
  resetCurrentClient,
  setCurrentClientState,
  setShouldMutateCurrentClient,
} from '@/app/redux/slices/current-client-slice';
import { openToast } from '@/app/redux/slices/toast-slice';
import { useAppDispatch, useAppSelector } from '@/app/redux/store';
import { truncateWithLength } from '@helpers/common-helpers';
import fetchCorlWithToken from '@helpers/fetch-corl-with-token';
import { COMMON_PERMISSION_ID, can } from '@helpers/permission-helper';
import { ICurrentClient, IGetCurrentClientResponse, ISearchCurrentClientsResponse } from '@type/clients';
import { IError } from '@type/common';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Button from '@components/button';
import Dropdown, { SettingMenu } from '@components/dropdown';
import { Icon } from '@components/icon';

import useAuth0Token from '@hooks/use-auth0-token';
import useFetchCorlPortal from '@hooks/use-fetch-corl-portal';

import { ROUTE } from '@constants/portal';
import { SWITCH_CLIENT_MESSAGE, TOAST_TYPE } from '@constants/texts';
import { USER_ACTION } from '@constants/users';

import SelectClientModal from './select-client-modal';
import useDirtyGlobal from '@hooks/use-dirty-global';
import { resetDirty } from '@/app/redux/slices/dirty-slice';
import { useRouter } from 'next/navigation';
import Logger from '@helpers/logger';

const ClientSelection = () => {
  const [isOpenSelectModal, setOpenSelectModal] = useState<boolean>(false);
  const { data: mainData, mutate, loading } = useFetchCorlPortal<IGetCurrentClientResponse>('current-client');
  const { data: clientsList, mutate: mutateClientList } = useFetchCorlPortal<ISearchCurrentClientsResponse>('current-client/options');
  const [isSwitching, setSwitching] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { token } = useAuth0Token();
  const openSelectClientModalWithVerifyData = async () => {
    await mutateClientList();
    setOpenSelectModal(true);
  };
  const { router, dirtyModal, checkedCallback } = useDirtyGlobal({
    handleOpenSwitchModal: openSelectClientModalWithVerifyData
  });
  const routerWithoutCheck = useRouter();
  const permissionContext = useContext(PermissionContext);
  const { name: clientName, id: clientId, shouldMutateCurrentClient } = useAppSelector((state) => state.currentClient);

  const listAction: SettingMenu[] = useMemo(
    () => [
      {
        key: 'projects',
        label: 'Projects',
        handle: () => router.push(`/projects`),
        action: USER_ACTION.VLP
      },
      {
        key: 'clientDetails',
        label: 'Client Details',
        handle: () => router.push(`/client?id=${clientId}`),
        action: USER_ACTION.VC
      },
    ],
    [router, clientId]
  );

  useEffect(() => {
    if (shouldMutateCurrentClient) {
      mutate();
      mutateClientList();
      dispatch(setShouldMutateCurrentClient(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldMutateCurrentClient]);

  const menuItems = listAction.filter((item) => {
    const objectId = item.action == USER_ACTION.VC ? clientId : COMMON_PERMISSION_ID;
    return !item.action || can(permissionContext.viewListObjectPermissionContext, item.action, objectId);
  });

  const handleChooseClient = useCallback(
    async (client: ICurrentClient, callback?: () => void, isAutoSwitch?: boolean) => {
      setSwitching(true);
      try {
        const res = await fetchCorlWithToken<IGetCurrentClientResponse | IError>('current-client', token, {
          method: 'PUT',
          body: JSON.stringify({
            clientId: client.id,
          }),
        });

        const errMessage = (res as IError)?.message;
        if (errMessage) {
          !isAutoSwitch &&
            dispatch(
              openToast({
                type: TOAST_TYPE.ERROR,
                message: `${SWITCH_CLIENT_MESSAGE.ERROR}. ${errMessage}`,
              })
            );
          setSwitching(false);
        } else {
          !isAutoSwitch &&
            dispatch(
              openToast({
                type: TOAST_TYPE.SUCCESS,
                message: messageResources.t('CLIENT_SWITCHED_SUCCESSFULLY_PROMPT'),
              })
            );
          await mutate();
          setSwitching(false);
          callback && callback();
          dispatch(resetDirty());
          dispatch(setCurrentClientState((res as IGetCurrentClientResponse).currentClient))
          routerWithoutCheck.push(`${ROUTE.PROJECTS}?forceReload=true`);
        }
      } catch (e) {
        !isAutoSwitch &&
          dispatch(
            openToast({
              type: TOAST_TYPE.ERROR,
              message: SWITCH_CLIENT_MESSAGE.ERROR,
            })
          );
        setSwitching(false);
      }
    },
    [dispatch, token, mutate, routerWithoutCheck]
  );

  useEffect(() => {
    if (mainData && clientsList) {
      if (mainData.currentClient) {
        dispatch(setCurrentClientState(mainData.currentClient));
      } else {
        dispatch(resetCurrentClient());
        if (clientsList.clients.length === 1) {
          handleChooseClient(clientsList.clients[0], () => null, true).then(
            () => setOpenSelectModal(false)
          ).catch((e: Error) => Logger.error("Error when select client", e));

        } else {
          setOpenSelectModal(true);
        }
      }
    }
  }, [mainData, dispatch, clientsList, handleChooseClient]);

  const renderLabel = (item: SettingMenu) => (
    <div className="flex items-center w-[131px]">
      <label className="text-blue-primary text-sm font-light leading-[normal] pt-[2px] cursor-pointer">
        {item.label}
      </label>
    </div>
  );

  return (
    <div className="flex items-center mr-4 sm:mr-[60px]">
      <div className="flex gap-[13px] items-center">
        {!loading && (
          <>
            {mainData?.currentClient && clientName && (
              <Dropdown menuItems={menuItems} renderLabel={renderLabel}>
                <button
                  className="group flex border-[1px] border-white rounded-md items-center justify-center text-center text-pink-secondary bg-transparent p-[16px] pr-[10px] gap-3 cursor-pointer font-normal text-sm leading-[normal] h-[50px]"
                  onClick={(e) => e.stopPropagation()}
                >
                  {truncateWithLength(clientName, 50)}
                  <Icon variant="dropdown-select" />
                </button>
              </Dropdown>
            )}
            {mainData?.currentClient ? (
              <>
                {clientsList?.clients && clientsList?.clients.length > 1 && (
                  <Button
                    className="primary"
                    size="sm"
                    onClick={checkedCallback.handleOpenSwitchModal}
                  >
                    Switch Client
                  </Button>
                )}
              </>
            ) : (
              <Button className="primary" size="sm" onClick={() => { void openSelectClientModalWithVerifyData() }}>
                Select Client
              </Button>
            )}
          </>
        )}
      </div>
      <SelectClientModal
        isOpenSelectModal={isOpenSelectModal}
        handleCloseModal={() => setOpenSelectModal(false)}
        clientsList={clientsList?.clients || []}
        handleChooseClient={handleChooseClient}
        isLoading={isSwitching}
      />
      {dirtyModal}
    </div>
  );
};

export default ClientSelection;
