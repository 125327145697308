'use client'

import { ReactNode, useEffect, useMemo, useState } from 'react';

import { PERMISSION_CONTEXT_STATUS, PermissionContext } from '@/app/contexts/permission-context';
import { useAppSelector } from '@/app/redux/store';
import WrapperComponent from '@components/wrapper-component';
import { PORTAL_OBJECT_TYPE } from '@constants/portal';
import { USER_ACTION } from '@constants/users';
import { can } from '@helpers/permission-helper';
import useAuth0Token from '@hooks/use-auth0-token';
import { PermissionRequest } from '@hooks/use-permission';
import { fetchPagePermissions } from '@services/permission-service';
import { ObjectPermissionContext } from '@type/permission';
import _ from 'lodash';
import { usePathname } from 'next/navigation';

export const defaultObjectPermissionContext: ObjectPermissionContext = {}

function Authorization({ children }: { readonly children: ReactNode }) {
  const [objectPermissionContext, setObjectPermissionContext] = useState<ObjectPermissionContext>(defaultObjectPermissionContext);
  const [permissionContextStatus, setPermissionContextStatus] = useState<PERMISSION_CONTEXT_STATUS>(PERMISSION_CONTEXT_STATUS.LOADING);
  const [viewListObjectPermissionContext, setViewListObjectPermissionContext] = useState<ObjectPermissionContext>(defaultObjectPermissionContext);
  const { token } = useAuth0Token();
  const pathname = usePathname();
  const { id: clientId } = useAppSelector((state) => state.currentClient);


  useEffect(() => {
    setObjectPermissionContext(defaultObjectPermissionContext);
    setPermissionContextStatus(PERMISSION_CONTEXT_STATUS.LOADING);
  }, [pathname])

  useEffect(() => {
    if (!token || !clientId) return;

    const clientDetailpermissionRequests: PermissionRequest<undefined>[] = [
      {
        objectType: PORTAL_OBJECT_TYPE.CLIENT,
        objectIds: [clientId],
        actions: [USER_ACTION.VC],
      },
      {
        objectType: [PORTAL_OBJECT_TYPE.TENANT, PORTAL_OBJECT_TYPE.CLIENT, PORTAL_OBJECT_TYPE.PROJECT],
      }
    ];

    if (token && clientId) {
      void fetchPagePermissions(clientDetailpermissionRequests, token, true).then((objectPermissionContext) => {
        setViewListObjectPermissionContext(objectPermissionContext);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clientId]);

  const permissionContextValue = useMemo(() => {
    const getMainPermissionRequests = (permissionRequests: PermissionRequest[]): PermissionRequest[] => {
      return permissionRequests.filter(permissionRequest => permissionRequest.mainPermission);
    }

    const hasMainPermission = (mainPermissions: PermissionRequest[], objectPermissionContext: ObjectPermissionContext) => {
      return mainPermissions.some(mainPermission => {
        if (_.isEmpty(mainPermission.actions) && mainPermission.actions?.length == 0 || _.isEmpty(mainPermission.objectIds)) return false;
        const action = mainPermission?.actions?.[0];
        const objectId = mainPermission?.objectIds?.[0];
        return can(objectPermissionContext, action as USER_ACTION, objectId, mainPermission.objectType as PORTAL_OBJECT_TYPE)
      });
    }

    const loadObjectPermissionContext = (permissionRequests: PermissionRequest[]): void => {
      if (_.isEmpty(permissionRequests)) {
        setPermissionContextStatus(PERMISSION_CONTEXT_STATUS.AUTHORIZED);
        return;
      }

      void fetchPagePermissions(permissionRequests, token).then((objectPermissionContext) => {
        setObjectPermissionContext(objectPermissionContext);
        const mainPermissions = getMainPermissionRequests(permissionRequests);
        if (!_.isEmpty(mainPermissions) && !hasMainPermission(mainPermissions, objectPermissionContext)) {
          setPermissionContextStatus(PERMISSION_CONTEXT_STATUS.UNAUTHORIZED);
          return;
        }
        setPermissionContextStatus(PERMISSION_CONTEXT_STATUS.AUTHORIZED);
      });
    }

    return {
      objectPermissionContext,
      viewListObjectPermissionContext,
      permissionContextStatus,
      setPermissionContextStatus,
      loadObjectPermissionContext
    };
  }, [objectPermissionContext, viewListObjectPermissionContext, permissionContextStatus, token]);

  if (token) {
    return (
      <PermissionContext.Provider value={permissionContextValue}>
          <WrapperComponent>
            {children}
          </WrapperComponent>
      </PermissionContext.Provider>
    );
  }
}

export default Authorization;
