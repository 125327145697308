'use client'

import Link from 'next/link';

import Information from '@components/information';
import { useFetchContactUsLink } from '@helpers/fetch-contact-us-link';

const NoAssignedClients = () => {
  const {noAssignedClientsSupportLink, loading} = useFetchContactUsLink();

  return (
    <>
    {!loading && (
    <Information
      headerText="You currently do not have any assigned clients"
    >
      <p className="text-xl font-medium text-black-title">
        Please reach out to Meditology support for assistance.
        <Link data-testid="support-link" href={noAssignedClientsSupportLink} rel="noopener noreferrer" target="_blank">
          <span className="underline text-blue-link ml-1">Contact Support</span>
        </Link>
      </p>
    </Information>
    )}
    </>
  );
}

export default NoAssignedClients;
