interface Auth0Config {
  domain: string;
  clientId: string;
  audience?: string;
}

export function getConfig(): Auth0Config {
  return {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string,
    audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string
  };
}
