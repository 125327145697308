import { ReactElement, useEffect, useState } from 'react';

import Button from '../button';
import Checkbox from '../checkbox';
import { Icon } from '../icon';
import classNames from 'classnames';

type SearchInputPropType = {
  placeholder: string;
  onSearch: (val: string) => void;
  showCheckboxFilter?: boolean;
  showFilter?: boolean;
  checkboxLabel?: string | ReactElement;
  size?: 'sm' | 'md';
  className?: string;
  value?: string;
};

const SearchInput = ({
  placeholder,
  showCheckboxFilter = false,
  showFilter = false,
  checkboxLabel,
  onSearch,
  className,
  value = '',
  size = 'md',
}: SearchInputPropType) => {
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    setSearchKey(value);
  }, [value]);

  const handleSearch = () => {
    if (searchKey) {
      onSearch(searchKey);
    }
  };

  const handleClear = () => {
    setSearchKey('');
    onSearch('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(searchKey);
    }
  };

  return (
    <div className={classNames("w-full inline-flex gap-2", {
      'h-[44px]': size === 'md',
      'h-[32px]': size === 'sm',
    }, className)}>
      <form className={classNames("flex items-center", {
        'max-w-[374px] w-full': !className
      }, className)}>
        <label htmlFor="voice-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full h-full">
          <input
            type="text"
            id="search"
            autoComplete="off"
            className={classNames(`placeholder:text-[10px] placeholder:font-normal text-sm text-black-title h-full font-semibold leading-3 tracking-wider text-left bg-white border rounded-lg focus:ring-blue-500 focus:border-pupple-primary focus:outline-none block w-full `, {
              'p-4 pr-9 ps-5': size === 'md',
              'p-[10px] ps-[10px] pr-8': size === 'sm'
            })}
            placeholder={placeholder}
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button type="button" className="absolute inset-y-0 end-0 flex items-center pe-3">
            {searchKey ? (
              <Icon
                variant="close"
                onClick={handleClear}
                svgClassName={{ fill: 'fill-gray-icon' }}
                height={size === 'sm' ? '16' : '20'}
                width={size === 'sm' ? '16' : '20'}
              />
            ) : (
              <Icon
                variant="search"
                onClick={handleSearch}
                svgClassName={{ fill: 'fill-gray-icon' }}
                height={size === 'sm' ? '16' : '20'}
                width={size === 'sm' ? '16' : '20'}
              />
            )}
          </button>
        </div>
      </form>

      {showFilter && (
        <Button className="default h-full gap-[10px]">
          <Icon variant="filter" svgClassName={{ fill: 'fill-gray-icon' }} /> FILTERS
        </Button>
      )}

      {showCheckboxFilter && (
        <Button className="default h-full font-medium leading-[13px] tracking-wider">
          <Checkbox label={checkboxLabel} />
        </Button>
      )}
    </div>
  );
};

export default SearchInput;
