import { PERMISSION_CONTEXT_STATUS, PermissionContext } from "@/app/contexts/permission-context";
import { UserType } from "@/app/models/user";
import { setHeaderState } from "@/app/redux/slices/header-slice";
import { handleToggleNavigation } from "@/app/redux/slices/navigation-history-slice";
import { useAppDispatch } from "@/app/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import Configurations from "@components/configurations";
import NotFound from "@components/not-found";
import PageHeader from "@components/page-header";
import ResponseStatus from "@components/response-status";
import SessionTimeOut from '@components/session-time-out';
import StatusCard from "@components/status-card";
import Toast from "@components/toast";
import UserSetting from "@components/user-setting";
import { NAVIGATION_EVENT_TYPE, NAVIGATION_TYPE } from "@constants/commom";
import { ROOT_URL_INDEX, ROUTE } from "@constants/portal";
import { DEFAULT_DIRTY_STATE, DEFAULT_NAVIGATION_HISTORY, KEYS } from "@constants/storage";
import { getSessionStorageItem, getUrl, setSessionStorageItem, updateSessionStorageItem } from "@helpers/common-helpers";
import { PermissionRequest } from "@hooks/use-permission";
import { NavigationHistory } from "@type/common";
import { Spin } from "antd";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { ReactNode, useContext, useEffect, useState } from "react";

export class PushStateEvent extends Event {
  targetUrl: string = '';
}

const WrapperComponent = ({ children }: { children: ReactNode }) => {
  const permissionContext = useContext(PermissionContext);
  const router = useRouter();
  const { user } = useAuth0<UserType>();
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const searchParams = useSearchParams();

  const url = getUrl(pathName, searchParams.toString());

  const isAuthorized = permissionContext.permissionContextStatus === PERMISSION_CONTEXT_STATUS.AUTHORIZED;
  const isUnauthorized = permissionContext.permissionContextStatus === PERMISSION_CONTEXT_STATUS.UNAUTHORIZED;
  const isLoading = permissionContext.permissionContextStatus === PERMISSION_CONTEXT_STATUS.LOADING;
  const isNotFound = permissionContext.permissionContextStatus === PERMISSION_CONTEXT_STATUS.NOT_FOUND;

  const [content, setContent] = useState<ReactNode | null>(null);

  const initialNavigationHistory = () => {
    if (!getSessionStorageItem(KEYS.NAVIGATION_HISTORY)) {
      setSessionStorageItem(KEYS.NAVIGATION_HISTORY, DEFAULT_NAVIGATION_HISTORY);
    }
  }

  const initialDirtyState = () => {
    if (!getSessionStorageItem(KEYS.DIRTY_STATE)) {
      setSessionStorageItem(KEYS.DIRTY_STATE, DEFAULT_DIRTY_STATE);
    }
  }

  useEffect(() => {
    initialNavigationHistory();
    initialDirtyState();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const pushState = window.history.pushState;
    window.history.pushState = function(state, unused, url) {
        const event = new PushStateEvent("pushstate"); 
        event.targetUrl = url as string;
        pushState.call(window.history, state as object, unused, url as string);
        window.dispatchEvent(event);
    };
  }, [])

  // to detect navigation type
  useEffect(() => {
    const updateNavigationHistory = (newUrl: string, navigationEventType: NAVIGATION_EVENT_TYPE, activeUrl='') => {
      updateSessionStorageItem<NavigationHistory>(KEYS.NAVIGATION_HISTORY, item => {
        if (item.currentUrl !== newUrl && item.lastNavigationType !== NAVIGATION_TYPE.RELOAD) {
          item.lastNavigationEventType = navigationEventType;
          item.previousUrl = activeUrl || item.currentUrl;
          item.currentUrl = newUrl;
          item.beforeReloadNavigationType = item.lastNavigationType;
          dispatch(
            handleToggleNavigation(false)
          );
        }
        return item;
      });
    }

    // reload
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const newUrl = (e.target as Window).location.pathname + (e.target as Window).location.search;
      updateSessionStorageItem<NavigationHistory>(KEYS.NAVIGATION_HISTORY, item => {
        item.lastNavigationType = NAVIGATION_TYPE.RELOAD;

        // Not reload current page - different url
        if (newUrl !== item.currentUrl) {
          item.originalUrl = '';
        }
        return item;
      });
      updateNavigationHistory(url, NAVIGATION_EVENT_TYPE.RELOAD)
    }

    // back / forward browser button or popstate
    const handlePopState = (e: PopStateEvent) => {
      const newUrl = (e.target as Window).location.pathname + (e.target as Window).location.search;

      updateSessionStorageItem<NavigationHistory>(KEYS.NAVIGATION_HISTORY, item => {
        if (newUrl === item.currentUrl) return item;
        // need to check if the last navigation type is push or back
        item.lastNavigationType = NAVIGATION_TYPE.BACK;
        item.originalUrl = '';
        return item;
      });
      updateNavigationHistory(newUrl, NAVIGATION_EVENT_TYPE.POP)
    }

    // link navigation
    const handlePushState = (e: PushStateEvent) => {
      const newUrl = e.targetUrl;
      updateSessionStorageItem<NavigationHistory>(KEYS.NAVIGATION_HISTORY, item => {
        if (newUrl === item.currentUrl) return item;
        item.lastNavigationType = NAVIGATION_TYPE.PUSH;
        return item;
      });
      updateNavigationHistory(newUrl, NAVIGATION_EVENT_TYPE.PUSH, url)
    }

    window.onpopstate = (e: PopStateEvent) => {
      setTimeout(() => {
        handlePopState(e);
      });
    }

    window.addEventListener('pushstate', handlePushState as EventListener);
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('pushstate', handlePushState as EventListener);
      window.onpopstate = () => {};
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [url])

  useEffect(() => {
    if (isUnauthorized || isNotFound) {
      setContent(null);
      if (content === null) {
        router.push('/404');
      }
    } else {
      setContent(children);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [permissionContext.permissionContextStatus, content]);

  const isRouteValid = Object.values(ROUTE).includes(url.split('?')[ROOT_URL_INDEX]);
  if (!isRouteValid) {
    dispatch(setHeaderState({ title: '' }));
    const permissionRequests: PermissionRequest[] = []
    permissionContext.loadObjectPermissionContext(permissionRequests);
  }
  
  return (
    <>
      <div className="flex-1">
        <UserSetting user={user as UserType} />
        <Toast />
        <Spin spinning={isLoading} size="large" className={`${isLoading ? '' : 'hidden'}`}>
          <PageHeader isShow={isAuthorized} />
          <ResponseStatus/>
          <Configurations/>
          <StatusCard />
          <main
            className={`max-w-[1280px] min-w-[560px] overflow-auto container pb-10 pt-[30px] px-6 sm:px-[42px] ${isAuthorized ? '' : 'invisible'}`}
          >
            {isRouteValid ? content : <NotFound />}
          </main>
        </Spin>
      </div>
      <SessionTimeOut />
    </>
  );
}

export default WrapperComponent;
