import { Avatar, AvatarProps } from 'antd';

type UserAvatarPropType = AvatarProps & ({
  firstName: string;
  lastName: string;
  src?: never;
} | {
  src: string;
  firstName?: never;
  lastName?: never;
});

const UserAvatar = ({ src, className, firstName, lastName, ...props }: UserAvatarPropType) => {
  const colors = [
    '#2196F3', // Blue
    '#4CAF50', // Green
    '#FF9800', // Orange
    '#E91E63', // Pink
    '#673AB7', // Purple
    '#FF5722', // Deep Orange
    '#9C27B0', // Deep Purple
    '#3F51B5', // Indigo
    '#8B7C30', // Deep Yellow
    '#00BCD4', // Cyan
  ];

  const generateColor = (name: string) => {
    const index = name.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const generateInitials = (firstName: string = 'User', lastName: string = '') => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastInitial = lastName ? lastName[0].toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  };
  const fullName = `${firstName ?? 'User'} ${lastName ?? ''}`;

  return (
    <>
      {src ? (
        <Avatar src={src} className={className} {...props} />
      ) : (
        fullName.trim() &&
        <Avatar
          style={{ backgroundColor: generateColor(fullName), verticalAlign: 'middle' }}
          className={className}
          {...props}
        >
          <div className='text-sm font-normal leading-[normal]'>{generateInitials(firstName, lastName)}</div>
        </Avatar>
      )}
    </>
  );
};

export default UserAvatar;
