import { PORTAL_OBJECT_TYPE } from "@constants/portal";
import { USER_ACTION } from "@constants/users";
import { mapResponseToDTO } from "../helpers/mapper-helper";
import { ObjectPermissionContext, ObjectPermissions, ObjectPermissionsApi, ViewListObjectPermissionApi } from "../types/permission";
import { getViewListPermissionActionByType } from "@helpers/permission-helper";

export function mapObjectPermissionApiToObjectPermissions(objectPermissionsApi: ObjectPermissionsApi): ObjectPermissions {
  return mapResponseToDTO<ObjectPermissions, ObjectPermissionsApi>(objectPermissionsApi, {});
}

export function mapViewListObjectPermissionApiToObjectPermissions(objectPermissionsApi: ViewListObjectPermissionApi): ObjectPermissionContext {
  const permission: ObjectPermissionContext = {};
  for (const [key, value] of Object.entries(objectPermissionsApi)) {
    if (value) {
      const type = key as PORTAL_OBJECT_TYPE;
      const objectPermission: ObjectPermissions = {COMMON_PERMISSION_ID: [getViewListPermissionActionByType(type) as USER_ACTION]}
      permission[type] = objectPermission;
    }
  }
  return permission;
}
