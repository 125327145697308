"use client";

import { useRouter } from 'next/navigation';
import { Icon } from '@components/icon';
import { isEmpty } from 'lodash';

interface BackButtonProps {
  name?: string;
}

const BackButton = ({ name }: BackButtonProps)=> {
  const router = useRouter();
  return (
    <button
      className="flex cursor-pointer max-w-full gap-1 mb-1.5"
      onClick={() => { router.back(); }}
      type="button"
    >
      <Icon variant="back-arrow" />
      <p className="font-bold text-xs text-pupple-secondary tracking-[0.6px]">{isEmpty(name) ? "BACK" : name}</p>
    </button>
  );
};

export default BackButton;
