import {usePathname, useSearchParams} from 'next/navigation';
import {useMemo} from 'react';
import { CrumbType } from './crumb';

export type UseBreadcrumbPropType = {
  getTextGenerator?: (param?: string, query?: {[key: string]: string}) => Promise<unknown>;
};

const useBreadcrumb = ({
  getTextGenerator = (param) => Promise.resolve(param),
}: UseBreadcrumbPropType) => {
  const pathname = usePathname();
  const searchParams = new URLSearchParams(useSearchParams());
  const asPath = `${pathname}?${searchParams.toString()}`;
  const asQuery = Object.fromEntries(searchParams.entries());

  const generatePathParts = (pathStr: string) => {
    const pathWithoutQuery = pathStr.split('?')[0];
    return pathWithoutQuery.split('/').filter(v => v.length > 0);
  };

  const breadcrumbs: CrumbType[] = useMemo(() => {
    const asPathNestedRoutes = generatePathParts(asPath);
    const pathnameNestedRoutes = generatePathParts(pathname);

    const crumblist = asPathNestedRoutes.map((subpath, idx) => {
      const param = pathnameNestedRoutes[idx].replace('[', '').replace(']', '');
      const url = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/');
      return {
        url,
        id: idx.toString(),
        textGenerator: idx === 0 ? undefined : () => getTextGenerator(param, asQuery),
        text: subpath.charAt(0).toUpperCase() + subpath.slice(1),
      };
    });

    return crumblist;
  }, [asPath, pathname, getTextGenerator, asQuery]);

  return {breadcrumbs};
};

export default useBreadcrumb;
