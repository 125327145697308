'use client'

import Image from 'next/image';
import Link from 'next/link';

import Footer from '@components/footer';
import { FC, ReactNode } from 'react';

interface IInformationProps {
  headerText: string;
  children: ReactNode;
}

const Information: FC<IInformationProps> = ({ headerText, children }) => {
  return (
    <>
      <main className="relative justify-center flex-1 min-w-[360px] container">
        <div className="flex justify-center flex-col items-center px-5">
          <div className="max-w-[1170px] w-full mt-[50px] mb-[90px]">
            <Link href="/">
              <Image src="/footerIcon.svg" alt="logo" width={265} height={60} />
            </Link>
          </div>

          <div className="bg-white px-4 sm:p-6 md:px-[10px] md:py-[110px] h-[350px] w-full max-w-[1170px] text-center justify-center flex flex-col items-center rounded shadow">
            <p className="text-4xl font-light mb-6 text-[#092C4C]">{headerText}</p>
            {children}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Information;
