import { useAppSelector } from '@/app/redux/store';
import { ICurrentClient } from '@type/clients';
import { useCallback, useEffect, useState } from 'react';
import Button from '@components/button';
import { Icon } from '@components/icon';
import Modal from '@components/modal';
import SearchInput from '@components/search-input';

import ClientCard from './client-card';
import { Spin } from 'antd';

type SelectClientModalType = {
  isOpenSelectModal: boolean;
  handleCloseModal: () => void;
  clientsList: ICurrentClient[];
  handleChooseClient: (client: ICurrentClient, callback: () => void) => Promise<void>;
  isLoading: boolean
};

const SelectClientModal = ({ isOpenSelectModal, handleCloseModal, clientsList, handleChooseClient, isLoading }: SelectClientModalType) => {
  const [clients, setClients] = useState<ICurrentClient[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const currentClient = useAppSelector((state) => state.currentClient);

  const handleSortClient = useCallback(
    (clients: ICurrentClient[]) => {
      if (currentClient && clients.find((c) => c.id === currentClient.id)) {
        const filteredClients = clients.filter((client) => client.id !== currentClient.id) || [];
        setClients([currentClient, ...filteredClients]);
      } else {
        setClients(clients || []);
      }
    },
    [currentClient]
  );

  useEffect(() => {
    handleSortClient(clientsList);
  }, [clientsList, handleSortClient]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filteredClients = clientsList.filter((client) => client.name.toLowerCase().includes(value.toLowerCase()));
    handleSortClient(filteredClients || []);
  };

  const handleCloseAndReset = () => {
    handleSortClient(clientsList);
    setSearchText('');
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={isOpenSelectModal}
      handleCloseModal={handleCloseAndReset}
      contentClassName="!px-5 !pb-6 !pt-4"
      className="w-[65vw] sm:!max-w-[65vw] h-[75vh] bg-white overflow-y-auto"
      disableEsc={currentClient.id === ''}
      isLoading={isLoading}
    >
      <Spin spinning={isLoading}>
        <div className="flex justify-between items-center mb-[15px]">
          <div className="color-blue-primary text-lg font-light leading-8 tracking-[-0.54px]">Select Client</div>

          {currentClient.id !== '' &&
            <Button className="default p-1 border-none" size="sm" onClick={handleCloseAndReset}>
              <Icon variant="close" svgClassName={{ fill: 'fill-black' }} width="16" height="16" />
            </Button>
          }
        </div>
        <SearchInput placeholder="Search Client Name" onSearch={handleSearch} size="sm" className="w-full" />
        <div className="border border- border-gray-border rounded-md mt-[13px] w-[63vw] h-[61vh] flex gap-2 flex-col overflow-y-auto" data-testid="clients-list">
          {clients.length > 0 ? (
            clients.map((client) => (
              <ClientCard
                key={client.id}
                searchText={searchText}
                client={client}
                onClick={(client) => handleChooseClient(client, () => {
                  setSearchText('');
                  handleCloseModal();
                })}
                checked={client.id === currentClient.id}
              />
            ))
          ) : (
            <div className="m-auto">No Clients</div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default SelectClientModal;
