'use client';

import { useEffect, useState, FC } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Dialog from '@components/dialog';
import { useAppDispatch } from '@/app/redux/store';
import { setSessionTimeOut } from '@/app/redux/slices/app-slice';
import { useAuth0 } from '@auth0/auth0-react';
import { messageResources } from '@/app/data/i18n';

enum SESSION_TIMEOUT_MESSAGE {
  KEEP_HERE = 'KEEP_HERE',
  LEAVE_HERE = 'LEAVE_HERE',
}

interface IIdleTimerProps {
  timeout: number; // ms
  promptBeforeIdle: number; // ms
}

const IdleTimer: FC<IIdleTimerProps> = ({ timeout, promptBeforeIdle }) => {
  const [remaining, setRemaining] = useState<number>(timeout)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const { logout } = useAuth0();

  const setAppSessionTimeOut = () => {
    dispatch(setSessionTimeOut(true));
  }

  const onIdle = () => {
    setAppSessionTimeOut();
  }

  const onPrompt = () => {
    setIsConfirmModalOpen(true);
  }

  const onMessage = (message: SESSION_TIMEOUT_MESSAGE) => {
    if (message === SESSION_TIMEOUT_MESSAGE.KEEP_HERE) {
      setIsConfirmModalOpen(false);
    } else if (message === SESSION_TIMEOUT_MESSAGE.LEAVE_HERE) {
      void logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  const idleTimer = useIdleTimer({
    onIdle,
    onPrompt,
    onMessage,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(idleTimer.getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleClickNo = () => {
    idleTimer.message(SESSION_TIMEOUT_MESSAGE.LEAVE_HERE);
    void logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const handleClickYes = () => {
    idleTimer.activate()
    setIsConfirmModalOpen(false);
    idleTimer.message(SESSION_TIMEOUT_MESSAGE.KEEP_HERE);
  }

  const questionMessage = messageResources.t('REMIND_LOG_OUT_PROMPT').replace('${remaining}', remaining.toString());

  return (
    <Dialog
      title={messageResources.t('SESSION_EXPIRE_PROMPT')}
      questionMessage={questionMessage}
      isOpen={isConfirmModalOpen}
      secondaryBtnText="NO"
      primaryBtnText="YES"
      handleCloseModal={handleClickYes}
      onSecondaryClick={handleClickNo}
      onPrimaryClick={handleClickYes}
    />
  );
};

export default IdleTimer;
