import { PermissionContext } from '@/app/contexts/permission-context';
import { UserType } from '@/app/models/user';
import { useAuth0 } from '@auth0/auth0-react';
import { COMMON_PERMISSION_ID, can } from '@helpers/permission-helper';

import { FC, useContext, useMemo } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import UserAvatar from '@components/avatar';
import Dropdown, { SettingMenu } from '@components/dropdown';
import { Icon } from '@components/icon';

import { USER_ACTION } from '@constants/users';

import ClientSelection from '../client-selection';
import useUserInfo from '@hooks/use-user-info';
import useDirtyGlobal from '@hooks/use-dirty-global';
import { useAppSelector } from '@/app/redux/store';
import { HEADER_TEXTS } from '@constants/texts';

interface IUserSettingProps {
  user?: UserType;
}

export const UserSetting: FC<IUserSettingProps> = () => {
  const permissionContext = useContext(PermissionContext);
  const {router, dirtyModal} = useDirtyGlobal();
  const { logout } = useAuth0();
  const { firstName, lastName } = useUserInfo();
  const { defaultPageSize } = useAppSelector((state) => state.configurations);

  const listAction: SettingMenu[] = useMemo(
    () => [
      {
        key: 'users',
        label: HEADER_TEXTS.USERS_MANAGEMENT,
        action: USER_ACTION.VLU,
        handle: () => router.push(
          ('/users?type=MEDITOLOGY&page=0&sortDir=ASC&sortBy=firstName&size={size}&text=').replace('{size}', defaultPageSize)),
      },
      {
        key: 'clients',
        label: HEADER_TEXTS.CLIENT_MANAGEMENT,
        action: USER_ACTION.VLC,
        handle: () => router.push(
          ('/clients?page=0&sortDir=ASC&sortBy=name&size={size}&nameCode=').replace('{size}', defaultPageSize)),
      },
    ],
    [router, defaultPageSize]
  );

  const menuItems = listAction.filter((item) => {
    return !item.action || can(permissionContext.viewListObjectPermissionContext, item.action, COMMON_PERMISSION_ID);
  });
  const renderLabel = (item: SettingMenu) => (
    <label className="text-blue-primary text-sm font-light leading-[normal] pt-[2px] cursor-pointer ">
      {item.label}
    </label>
  );

  return (
    <div className="bg-blue-primary w-ful">
      <div className="w-full max-w-[1280px] px-6 sm:px-[55px] conatiner mx-auto flex justify-between items-center h-[70px]">
        <Link href="/" className="my-auto mx-0 relative  w-[36px] h-[36px] sm:w-[44px] sm:h-[44px]" onClick={(e) => {
          e.preventDefault();
          router.push("/")
        }}>
          <Image src="/navIcon.svg" alt="logo" fill={true} />
        </Link>
        <div className="flex items-center">
          {menuItems.length > 0 && <Dropdown
            renderLabel={(item) => <div className="flex items-center w-[131px]">{renderLabel(item)}</div>}
            menuItems={menuItems}
          >
            <button
              data-testid="admin"
              className="group flex items-center mr-4 sm:mr-8 justify-center text-white bg-transparent px-1 gap-3 cursor-pointer font-medium text-sm leading-[normal] h-[50px]"
              onClick={(e) => e.stopPropagation()}
            >
              Admin
              <Icon variant="dropdown-select" />
            </button>
          </Dropdown>
          }
          <ClientSelection />
          <Dropdown
            renderLabel={(item) => <div className="flex items-center w-[87px]">{renderLabel(item)}</div>}
            menuItems={[
              {
                key: 'logout',
                label: 'Log Out',
                handle: () => {
                  void logout({ logoutParams: { returnTo: window.location.origin } })
                },
              },
            ]}
          >
            <button
              className="group flex items-center justify-center bg-transparent gap-1 h-[50px] px-1"
              onClick={(e) => e.stopPropagation()}
              data-testid="account"
            >
              <UserAvatar firstName={firstName ?? ''} lastName={lastName ?? ''} size={40} className="cursor-pointer" />
              <Icon variant="dropdown-select" />
            </button>
          </Dropdown>
        </div>
      </div>
      {dirtyModal}
    </div>
  );
};

export default UserSetting;
