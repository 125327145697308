import { PORTAL_OBJECT_TYPE } from '@constants/portal';
import _ from 'lodash';
import { API_ENDPOINT } from "../constants/api";
import { PermissionRequest } from '../hooks/use-permission';
import { mapObjectPermissionApiToObjectPermissions, mapViewListObjectPermissionApiToObjectPermissions } from '../mappers/permission-mapper';
import { ObjectPermissionContext, ObjectPermissions, ObjectPermissionsApi, ViewListObjectPermissionApi } from "../types/permission";
import { get, post } from "./api-service";

export interface ViewListObjectPermissionsParams{
  objectTypes: PORTAL_OBJECT_TYPE[];
}

async function buildPermissionRequest(params: PermissionRequest, token: string | null): Promise<ObjectPermissionContext> {
  let res;
  if (typeof params.objectType !== 'string') {
    const requestParams = {
      objectTypes: params.objectType as PORTAL_OBJECT_TYPE[]
    }
    res = await get<ViewListObjectPermissionApi>(API_ENDPOINT.CHECK_VIEW_LIST_OBJECT_PERMISSION, token, requestParams);
    return mapViewListObjectPermissionApiToObjectPermissions(res)
  } else {
    res = await post<ObjectPermissionsApi>(API_ENDPOINT.CHECK_PERMISSIONS, token, params);
    const mappedData: ObjectPermissions = mapObjectPermissionApiToObjectPermissions(res);
      return { [params.objectType]: mappedData } as unknown as ObjectPermissionContext;
  }
}

export async function fetchPagePermissions (requests: PermissionRequest[], token: string | null, mergePermission = false): Promise<ObjectPermissionContext> {
  const objectPermissionsPromiseAll: Promise<ObjectPermissionContext>[] = [];

  requests.forEach(request => {
    if (typeof request.objectType === 'string' && _.isEmpty(request.objectIds)) {
      return;
    }

    objectPermissionsPromiseAll.push(buildPermissionRequest(request, token));
  })

  const permissionObject: ObjectPermissionContext = {}

  return Promise.all(objectPermissionsPromiseAll)
  .then((responses) => {
    responses.forEach((permission) => {
      if (mergePermission) {
        _.mergeWith(permissionObject, permission, (a, b) => 
          _.isArray(b) ? b : undefined
        );
      } else {
        Object.assign(permissionObject, permission);
      }
    })

    return permissionObject;
  });
}
