'use client'

import Link from 'next/link';

import Information from '@components/information';
import { useFetchContactUsLink } from '@helpers/fetch-contact-us-link';

function UnAuthorize() {
  const {unAuthorizedSupportLink, loading} = useFetchContactUsLink();

  return (
    <>
    {!loading && (
    <Information
      headerText="You are not authorized to access this portal"
    >
      <p className="text-xl font-medium text-black-title">
        For assistance, please contact Meditology support.
        <Link data-testid="support-link" href={unAuthorizedSupportLink} rel="noopener noreferrer" target="_blank">
          <span className="underline text-blue-link ml-1">Contact Us</span>
        </Link>
      </p>
    </Information>
    )}
    </>
  );
}

export default UnAuthorize;
