export const TOASTER_MESSAGE = {
  UNAUTHORIZED: 'Forbidden - You don&#146;t have permission to access this resource',
}

export const enum NAVIGATION_TYPE {
  BACK = 'back',
  REPLACE = 'replace',
  PUSH = 'push',
  RELOAD = 'reload',
  UNDETERMINED = 'undetermined',
}

export const enum NAVIGATION_EVENT_TYPE {
  POP = 'pop',
  REPLACE = 'replace',
  PUSH = 'push',
  RELOAD = 'reload',
  UNDETERMINED = 'undetermined',
}
