import {Fragment} from 'react';
import Crumb from './crumb';
import useBreadcrumb, {UseBreadcrumbPropType} from './use-breadcrumb';

const Breadcrumb = ({getTextGenerator}: UseBreadcrumbPropType) => {
  const {breadcrumbs} = useBreadcrumb({
    getTextGenerator,
  });

  return (
    <Fragment>
      {breadcrumbs.length > 1 && (
        <nav className="justify-between mb-[8px]" aria-label="Breadcrumb">
          <ol
            className={`text-xs font-medium leading-[14px] tracking-wider text-left inline-flex items-center mb-3 space-x-1 md:space-x-2 rtl:space-x-reverse sm:mb-0 text-pupple-secondary`}
          >
            {breadcrumbs.map((crumb, index) => (
              <Fragment key={crumb.id}>
                {index > 0 && <li key={crumb.id}>/</li>}
                <li key={crumb.text}>
                  <div className="flex items-center">
                    <Crumb
                      item={crumb}
                      key={crumb.id}
                      last={index === breadcrumbs.length - 1}
                    />
                  </div>
                </li>
              </Fragment>
            ))}
          </ol>
        </nav>
      )}
    </Fragment>
  );
};

export default Breadcrumb;
