import { Icon } from '@components/icon';
import { isEmpty } from 'lodash';

interface NextButtonProps {
  name?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const NextButton = ({ name, onClick, disabled = false }: NextButtonProps) => {
  return (
    <button
      className={`flex cursor-pointer max-w-15 gap-1 mb-1.5 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      <p className="font-bold text-xs text-pupple-secondary tracking-[0.6px]">{isEmpty(name) ? "NEXT" : name}</p>
      <Icon variant="next-arrow" />
    </button>
  );
};

export default NextButton;
