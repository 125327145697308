import useFetchCorlPortal from "@hooks/use-fetch-corl-portal";
import { IContactSupportLinksConfigResponse } from "@type/api";
import { useMemo } from "react";

export const useFetchContactUsLink = () => {
	const {data, loading} = useFetchCorlPortal<IContactSupportLinksConfigResponse>('public/configurations?codes=contactUsEmail,unAuthorizedContactUsSubject,noAssignedClientsContactUsSubject');

	const buildContactUsLink = (email: string, configurationSubject: string | undefined) => {
		const subject = configurationSubject ? `?subject=${configurationSubject}` : "";
		return `mailto:${email}${subject}`;
	}

	const unAuthorizedSupportLink = useMemo(() => {
			if (loading) {
				return "";
			}

			if (!data?.contactUsEmail) {
				return 'https://www.meditologyservices.com';
			}

			return buildContactUsLink(data.contactUsEmail, data?.unAuthorizedContactUsSubject);
	}, [loading, data?.contactUsEmail, data?.unAuthorizedContactUsSubject])

	const noAssignedClientsSupportLink = useMemo(() => {
		if (loading) {
			return "";
		}

		if (!data?.contactUsEmail) {
			return 'https://www.meditologyservices.com';
		}

		return buildContactUsLink(data.contactUsEmail, data?.noAssignedClientsContactUsSubject);
	}, [loading, data?.contactUsEmail, data?.noAssignedClientsContactUsSubject]);

	return {
			unAuthorizedSupportLink,
			noAssignedClientsSupportLink,
			loading
	};
};
