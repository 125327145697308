'use client';
import Link from 'next/link';
import {useEffect, useState} from 'react';

export type CrumbType = {
  id: string;
  text: string;
  url: string;
  textGenerator?: () => Promise<unknown>;
};
type CrumbPropType = {
  item: CrumbType;
  last: boolean;
};
const Crumb = ({item, last = false}: CrumbPropType) => {
  const [text, setText] = useState(item.text);

  useEffect(() => {
    const getTextFunction = async () => {
      if (!item.textGenerator) {
        return;
      } else {
        const finalText = await item.textGenerator();
        if (!finalText) {
          return;
        }
        setText(finalText as string);
      }
    };

    void getTextFunction();
  }, [item, item.textGenerator]);

  if (last) {
    return (
      <Link href="" className={'text-gray-text-secondary'}>
        {text}
      </Link>
    );
  }
  return <Link href={item.url}>{text}</Link>;
};

export default Crumb;
