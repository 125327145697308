'use client'

import { getConfig } from '@/app/helpers/auth-0-helper/config';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Suspense, useState } from 'react';
import Authentication from '../authentication';
import ReduxProvider from '@/app/redux/provider';
import PendoInit from '@components/pendo/pendo-init';
import ZendeskInit from '@components/zendesk/zendek-init';

function AuthRootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [isAuth0LoginRedirect, setIsAuth0LoginRedirect] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [doneCheckingAuthorizationOnPageReload, setDoneCheckingAuthorizationOnPageReload] = useState(false);
  const config = getConfig();

  const onRedirectCallback = (appState: AppState | undefined) => {
    setIsAuth0LoginRedirect(true);
    window.history.replaceState(
      {},
      document.title,
      appState?.returnTo || '/'
    );
  };

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: typeof window !== 'undefined' ? window?.location?.origin : '',
      ...(config.audience ? { audience: config.audience } : null),
    },
  };

  return (
    <ReduxProvider>
      <Suspense>
        <Auth0Provider {...providerConfig}>
          <Authentication
            isAuth0LoginRedirect={isAuth0LoginRedirect}
            onSetIsAuth0LoginRedirect={setIsAuth0LoginRedirect}
            onSetIsAuthorized={setIsAuthorized}
            setDoneCheckingAuthorizationOnPageReload={setDoneCheckingAuthorizationOnPageReload}
          >
            {children}
          </Authentication>
          <PendoInit 
            isAuthorized={isAuthorized}
            doneCheckingAuthorizationOnPageReload={doneCheckingAuthorizationOnPageReload}
          />
          <ZendeskInit 
            doneCheckingAuthorizationOnPageReload={doneCheckingAuthorizationOnPageReload}
          />
         </Auth0Provider>
      </Suspense>
    </ReduxProvider>
  );
}

export default AuthRootLayout;
