import { ICurrentClient } from '@type/clients';

import Highlighter from 'react-highlight-words';

import { Icon } from '@components/icon';
import classNames from 'classnames';

type ClientCardPropType = {
  client: ICurrentClient;
  key?: string;
  searchText?: string;
  onClick?: (client: ICurrentClient) => Promise<void>;
  checked?: boolean;
};

const ClientCard = ({ client, key, searchText, onClick, checked }: ClientCardPropType) => {
  return (
    <button
      key={key}
      className={classNames("shadow-stat-card px-[11px] py-[9px] rounded-md bg-gray-contacts border-[1px] border-transparent hover:border-pupple-hover flex justify-between gap-2 items-center", {
        "cursor-pointer": !checked,
      })}
      onClick={() => (onClick && !checked) && void onClick(client)}
    >
      <Highlighter
        autoEscape={true}
        className="color-gray-bold text-xs font-semibold leading-3 tracking-[0.36px] break-words line-clamp-3"
        searchWords={[searchText || '']}
        textToHighlight={client.name}
      />
      {checked && (
        <Icon
          variant="circle-success-check"
          svgClassName={{
            fill: `fill-green-primary`,
            stroke: 'stroke-green-secondary fill-green-secondary',
          }}
          width='14'
          height='14'
        />
      )}
    </button>
  );
};

export default ClientCard;
