import { DirtyState, NavigationHistory } from "@type/common";
import { NAVIGATION_EVENT_TYPE, NAVIGATION_TYPE } from "./commom";

export const KEYS = {
  NAVIGATION_HISTORY: 'navigation_history',
  DIRTY_STATE: 'dirty_state',
}

export const DEFAULT_NAVIGATION_HISTORY: NavigationHistory = {
  currentUrl: '',
  previousUrl: '',
  lastNavigationType: NAVIGATION_TYPE.UNDETERMINED,
  beforeReloadNavigationType: NAVIGATION_TYPE.UNDETERMINED,
  lastNavigationEventType: NAVIGATION_EVENT_TYPE.UNDETERMINED,
  originalUrl: '',
};

export const DEFAULT_DIRTY_STATE: DirtyState = {
  lastDirtyCheckPopUpOpened: false,
  lastCountPushState: 0,
}
