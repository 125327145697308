const NotFound = () => {
  return (
    <div className="flex justify-center flex-col items-center px-5">
      <div className="bg-white px-4 sm:p-6 md:px-[10px] md:py-[110px] h-[350px] w-full max-w-[1170px] text-center justify-center flex flex-col items-center rounded shadow">
        <p className="text-4xl font-light mb-6 text-[#092C4C]">Page not found</p>
      </div>
    </div>
  );
}

export default NotFound;
