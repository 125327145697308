'use client'
import { ReactNode } from 'react';

import { Dropdown as AntDropdown, DropdownProps, MenuProps } from 'antd';

import { USER_ACTION } from '@constants/users';

export type SettingMenu = {
  key: string;
  label: string;
  icon?: JSX.Element;
  action?: USER_ACTION;
  handle: () => void
};

type DropdownPropType = DropdownProps & {
  menuItems: SettingMenu[];
  renderLabel: (item: SettingMenu) => ReactNode;
};

const Dropdown = ({ menuItems, renderLabel, ...props }: DropdownPropType) => {
  const items: MenuProps['items'] = menuItems.map((item) => {
    return {
      key: item.key,
      label: renderLabel(item),
    };
  });
  return (
    <AntDropdown
      trigger={['click']}
      placement="bottomRight"
      menu={{
        items,
        onClick: (e) => {
          menuItems.find(item => item.key === e.key)?.handle()
        },
      }}
      {...props}
    />
  );
};

export default Dropdown;
