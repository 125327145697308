
import { convertObjectToQueryString } from '@helpers/common-helpers';
import fetchWithToken from '../helpers/fetch-with-token'
import _ from 'lodash';
import { RequestOptions } from '@helpers/fetch-corl-with-token';



export function get<T>(url: string, token: string | null, queryObject={}, options?: RequestOptions): Promise<T> {
  if (!_.isEmpty(queryObject)) {
    url += convertObjectToQueryString(queryObject);
  }
  return fetchWithToken<T>(url, token, {method: 'GET', ...options})
}


export function post<T>(url: string, token: string | null, bodyParams={}, options?: RequestOptions): Promise<T> {
  return fetchWithToken<T>(url, token, {method: 'POST', body: JSON.stringify(bodyParams), ...options})
}
