import { setStatusState } from '@/app/redux/slices/status-slice';
import { useAppDispatch } from '@/app/redux/store';
import useFetchCorlPortal from '@hooks/use-fetch-corl-portal';
import { IGetStatusOptionsResponse } from '@type/requests';
import { useEffect } from 'react';

const ResponseStatus = () => {
  const dispatch = useAppDispatch();
  const {
    data: optionsData,
  } = useFetchCorlPortal<IGetStatusOptionsResponse>(`request-item/response-statuses`, undefined, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (optionsData) {
      dispatch(
        setStatusState(
          optionsData
        )
      );
    }
  }, [dispatch, optionsData]);

  return <></>
}

export default ResponseStatus