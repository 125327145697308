'use client';

import { useEffect, useState } from 'react';
import Information from '@components/information';
import { useAuth0 } from '@auth0/auth0-react';

export const COUNT = 20; // seconds

const SessionTimeOutInfo = () => {
  const [count, setCount] = useState(COUNT);
  const { logout } = useAuth0();

  useEffect(() => {
    const timer = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (count === 0) {
      void logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }, [count, logout]);

  const goToLogOut = () => {
    void logout({ logoutParams: { returnTo: window.location.origin } });
  }

  return (
    <Information
      headerText="For security reasons, the session has been timed out due to inactivity"
    >
      <p className="text-xl font-medium text-black-title">
        <span>{`You will be redirected to login page in ${count} seconds.`}</span>
        <button className="underline text-blue-link ml-1 cursor-pointer"
          onClick={goToLogOut}
        >
          Go to Login Page now
        </button>
      </p>
    </Information>
  );
}

export default SessionTimeOutInfo;
