'use client'

import { TUserInfo } from '@type/users';

import { useEffect, useState } from 'react';

import useFetchCorlPortal from './use-fetch-corl-portal';
import { useAppDispatch } from '@/app/redux/store';
import { resetCurrentUser, setCurrentUserState } from '../redux/slices/current-user-slice';
import { USER_STATUS } from '@constants/users';

const useAuthorize = () => {
  const dispatch = useAppDispatch();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { data, error, loading: myInfoLoading } = useFetchCorlPortal<Response>('my-info', undefined, { refreshInterval: 60 * 1000 }, true);

  
  useEffect(() => {
    if(myInfoLoading) {
      return;
    }
    if (!data && error) {
      setIsAuthorized(false);
      dispatch(resetCurrentUser());
      setLoading(false);
      return;
    }

    data
      ?.json()
      .then((res: TUserInfo) => {
        if (res.status !== USER_STATUS.ACTIVE) {
          setIsAuthorized(false);
          setLoading(false);
          return;
        }
        setIsAuthorized(true);
        dispatch(setCurrentUserState(res));
        setLoading(false);
      })
      .catch(() => {
        setIsAuthorized(false);
        dispatch(resetCurrentUser());
        setLoading(false);
      });
  }, [data, dispatch, error, myInfoLoading]);

  return {
    loading,
    isAuthorized,
  };
};

export default useAuthorize;
