/**
 * Represents a logger that can be used to log messages.
 * For now, it will log using console, but in the future, it can be sent to the server
 */
class Logger {
    /**
     * Logs an informational message.
     * @param message - The message to log.
     */
     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static info(message: string) {
        console.info(`INFO: ${message}`);
    }

    /**
     * Logs a debug message.
     * @param message - The message to log.
     */
    static debug(message: string) {
        console.debug(`DEBUG: ${message}`);
    }

    /**
     * Logs an error message.
     */
    static error(message: string, error?: Error) {
        console.error("ERROR: ", message, error);
    }
}

export default Logger;