
import { setConfigurations } from '@/app/redux/slices/configuration-slice';
import { useAppDispatch } from '@/app/redux/store';
import useFetchCorlPortal from '@hooks/use-fetch-corl-portal';
import { IConfigurationsResponse } from '@type/api';
import { useEffect } from 'react';

const Configurations = () => {
  const dispatch = useAppDispatch();
  const { data } = useFetchCorlPortal<IConfigurationsResponse>('configurations?codes=defaultPageSize,fileSizeLimit');

  useEffect(() => {
    if (data) {
      dispatch(
        setConfigurations(
          data
        )
      );
    }
  }, [dispatch, data]);

  return <></>
}

export default Configurations
