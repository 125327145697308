'use client'

import Footer from '@/app/components/footer';
import { UserType } from '@/app/models/user';
import { useAuth0 } from '@auth0/auth0-react';

import { FC, ReactNode, useEffect } from 'react';

import { Spin } from 'antd';

import UnAuthorize from '@components/unauthorization';

import useAuthorize from '@hooks/use-authorized';

import { useAppSelector } from '@/app/redux/store';
import NoAssignedClients from '@components/no-assigned-clients';
import SessionTimeOutInfo from '@components/session-time-out-info';
import fetchWithToken from '@helpers/fetch-with-token';
import useFetchCorlPortal from '@hooks/use-fetch-corl-portal';
import { ISearchCurrentClientsResponse } from '@type/clients';
import Authorization from '../authorization';
import { useRouter } from 'next/navigation';

interface IAuthenticationProps {
  isAuth0LoginRedirect: boolean;
  onSetIsAuth0LoginRedirect: (isAuth0LoginRedirect: boolean) => void;
  onSetIsAuthorized: (isAuthorized: boolean) => void;
  setDoneCheckingAuthorizationOnPageReload: (isLoadingToCheckAuthorization: boolean) => void;
  children: ReactNode;
}

const Authentication: FC<IAuthenticationProps> = ({ children, isAuth0LoginRedirect, onSetIsAuth0LoginRedirect, onSetIsAuthorized, setDoneCheckingAuthorizationOnPageReload }) => {
  const { isAuthorized, loading: isAuthorizeLoading  } = useAuthorize();
  const { isSessionTimeout } = useAppSelector((state) => state.app);
  const router = useRouter();
  const { loginWithRedirect, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0<UserType>();

  const { data: clientsList, loading: isFetchingAssignedClientsList } = useFetchCorlPortal<ISearchCurrentClientsResponse>('current-client/options', undefined, { refreshInterval: 60 * 1000 }, false);

  useEffect(() => {
    const updateLastLogin = () => {
      if (isAuthenticated) {
        getAccessTokenSilently()
          .then((token) => {
            void fetchWithToken('last-login', token, {method: 'POST'});
          })
          .catch(() => {
            console.error('Fail fetch with token.');
          });
      }
    };

    if (isAuth0LoginRedirect) {
      updateLastLogin();
      onSetIsAuth0LoginRedirect(false);
    }
  }, [isAuth0LoginRedirect, onSetIsAuth0LoginRedirect, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (isLoading || isAuthorizeLoading || isFetchingAssignedClientsList) {
      return;
    }

    setDoneCheckingAuthorizationOnPageReload(true);
    if (!isAuthenticated || !isAuthorized || isSessionTimeout || clientsList?.clients.length == 0) {
      onSetIsAuthorized(false);
      return;
    }
    onSetIsAuthorized(true);
  }, [isAuthorized, isAuthenticated, clientsList?.clients, isSessionTimeout, onSetIsAuthorized, isLoading, isAuthorizeLoading, isFetchingAssignedClientsList, setDoneCheckingAuthorizationOnPageReload]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!isLoading && !isAuthenticated) {
    localStorage.setItem('first-login', 'true');
    void loginWithRedirect({
      appState: { returnTo: window.location.href },
      openUrl: (url) => {
        router.replace(url);
      }
    })

    return;
  }

  if (isLoading || isAuthorizeLoading) {
    return (
      <Spin spinning size="large">
        <div className="h-[100vh]"></div>
      </Spin>
    );
  }

  if (!isAuthorizeLoading && !isAuthorized) {
    return (
      <UnAuthorize />
    );
  }

  if (!isFetchingAssignedClientsList && clientsList?.clients.length === 0) {
    return (
      <NoAssignedClients />
    );
  }

  if (isSessionTimeout) {
    return (
      <SessionTimeOutInfo />
    );
  }

  return (
    <>
      <Authorization>
        {children}
      </Authorization>
      <Footer />
    </>
  );
}

export default Authentication;
