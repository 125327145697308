/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
"use client"

import useFetchCorlPortal from "@hooks/use-fetch-corl-portal";
import { IZenDeskConfigResponse } from "@type/api";
import Script from "next/script";
import { useMemo } from "react";

interface IZendeskInitProps {
  doneCheckingAuthorizationOnPageReload: boolean;
}

export default function ZendeskInit({doneCheckingAuthorizationOnPageReload}: IZendeskInitProps) {
  const { data, loading: loadingWidgetKey } = useFetchCorlPortal<IZenDeskConfigResponse>('public/configurations?codes=zendeskWidgetKey');

  return useMemo(() => {
    if (!doneCheckingAuthorizationOnPageReload || loadingWidgetKey || !data?.zendeskWidgetKey) {
      return <></>;
    }

    return (
      <Script
        async
        id="ze-snippet"
        data-testid="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${data?.zendeskWidgetKey}`}
      />
    );
  }, [doneCheckingAuthorizationOnPageReload, loadingWidgetKey, data?.zendeskWidgetKey]);
}
